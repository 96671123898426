<template>
<!--    <div class="site-wrapper animsition" data-animsition-in="fade-in" data-animsition-out="fade-out">-->
<div>
    <!--start For redirection -->
    <a :href="currentRedirectionURL" style="display: none;" rel="noreferrer" id="currentRedirectionURL">link</a>
    <a :href="currentRedirectionURL" style="display: none;" id="currentRedirectionURL_ref">link</a>
    <!--end For redirection -->
</div>
<!-- //wrapper -->
</template>

<script>
import {detectMobile} from "../utils/functions";
import {
    mapState
} from "vuex";
export default {
    name: "StoreRedirect",
    data() {
        return {
            currentRedirectionURL:'',
            isMobile: detectMobile(),
        };
    },
    watch: {
        '$route'(params) {
            this.indexMethod(params)
        }
    },
    computed: {
        ...mapState({
            storeProfileSearch: (state) =>
                state.coupon.storeProfileSearch ? state.coupon.storeProfileSearch : [],
        }),
        redrectionConditions(){
            if(this.storeProfileSearch.hasOwnProperty('redirection_condition')
            && this.storeProfileSearch.redirection_condition.length>0){
                return this.storeProfileSearch.redirection_condition[0]
            }
            return {}
        }
    },
    methods: {
        async indexMethod(params,q){
            let url = this.$router.currentRoute.path;
            var lastPart = url.split("/").pop();
            console.log(lastPart)
            console.log(params)
            await this.$store
                .dispatch('coupon/fetchSearchInStore', {
                    slug: params.slug
                })
                .then((res) => {});

             //redirection condition------ Mobile
             if(this.isMobile == true && this.redrectionConditions.hasOwnProperty('mobile_redirection_type')
            && (this.redrectionConditions.mobile_redirection_type == "zero-redirect-with-refer" 
            || this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer")
            ){
                if(this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer"){
                this.currentRedirectionMode = 'zrwor';
                this.$store.state.showLoader = false;

                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL').click();
                }, 1000);
               }else{
                this.currentRedirectionMode = 'zrwr';
                this.$store.state.showLoader = false;

                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL_ref').click();
                }, 1000);
               }
            }

            //redrection condition------ Desktop
            if(this.isMobile == false && this.redrectionConditions.hasOwnProperty('desktop_redirection_type')
            && (this.redrectionConditions.desktop_redirection_type == "zero-redirect-with-refer"
            || this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer")
            ){
                this.currentRedirectionURL = this.redrectionConditions.desktop_base_url;

               if(this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer"){
                this.currentRedirectionMode = 'zrwor';
                this.$store.state.showLoader = false;
               
                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL').click();
                }, 1000);
               }else{
                this.currentRedirectionMode = 'zrwr';
                this.$store.state.showLoader = false;
               
                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL_ref').click();
                }, 1000);
               }
            }

        }
    },
    created(){
        this.$store.state.showLoader = true;
        this.indexMethod(this.$route.params, this.$route.query)
    }
};
</script>

<style scoped>
</style>
